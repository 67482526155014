.delete-modal-button {
  background-color: #f1416c !important;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 80px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border: none !important;
}

.delete-modal-button:hover {
  background-color: #ff9999 !important;
  color: #ffffff !important;
}

.cancel-delete-modal-button {
  background-color: #ffffff !important;
  color: #9ca3af !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 80px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border: 1px solid #9ca3af !important;
}

.cancel-delete-modal-button:hover {
  background-color: #9ca3af !important;
  color: #ffffff !important;
}

.block-modal-button {
  background-color: #212121 !important;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 80px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border: none !important;
}

.block-modal-button:hover {
  background-color: #404040 !important;
  color: #ffffff !important;
}

.cancel-block-modal-button {
  background-color: #ffffff !important;
  color: #9ca3af !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 80px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border: 1px solid #9ca3af !important;
}

.cancel-block-modal-button:hover {
  background-color: #9ca3af !important;
  color: #ffffff !important;
}

.add-driver-button {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  width: 100px;
  padding: 9px;
  border-radius: 5px;
}

.add-driver-button:hover {
  background-color: #31205d;
  color: #ffffff;
}

.onlineClass {
  background-color: #e8fff3;
  padding-bottom: 4px;
  padding-top: 2px;
  color: #50cd89;
  border-radius: 6px;
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  text-align: center;
  width: 50%;
}

.offlineClass {
  background-color: #f1f2f4;
  padding-bottom: 4px;
  padding-top: 2px;
  color: #9ca3af;
  border-radius: 6px;
  /* padding-left: 10px; */
  /* padding-right: 8px; */
  text-align: center;
  width: 50%;
}

.onrideClass {
  background-color: #fffaeb;
  padding-bottom: 4px;
  padding-top: 2px;
  color: #ffc000;
  border-radius: 6px;
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  text-align: center;
  width: 50%;
}
