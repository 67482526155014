.onlineClass {
    background-color: #e8fff3;
    padding-bottom: 4px;
    padding-top: 2px;
    color: #50cd89;
    border-radius: 6px;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
    width: auto;
  }
  
  .offlineClass {
    background-color: #f1f2f4;
    padding-bottom: 4px;
    padding-top: 2px;
    color: #9ca3af;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
    width: auto;
  }