.delete-modal-button {
  background-color: #f1416c !important;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 80px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border: none !important;
}

.delete-modal-button:hover {
  background-color: #ff9999 !important;
  color: #ffffff !important;
}

.cancel-delete-modal-button {
  background-color: #ffffff !important;
  color: #9ca3af !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 80px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border: 1px solid #9ca3af !important;
}

.cancel-delete-modal-button:hover {
  background-color: #9ca3af !important;
  color: #ffffff !important;
}

.block-modal-button {
  background-color: #212121 !important;
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 80px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border: none !important;
}

.block-modal-button:hover {
  background-color: #404040 !important;
  color: #ffffff !important;
}

.cancel-block-modal-button {
  background-color: #ffffff !important;
  color: #9ca3af !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  width: 80px !important;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border: 1px solid #9ca3af !important;
}

.cancel-block-modal-button:hover {
  background-color: #9ca3af !important;
  color: #ffffff !important;
}

.add-driver-button {
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  width: 100px;
  padding: 9px;
  border-radius: 5px;
}

.add-driver-button:hover {
  background-color: #31205d;
  color: #ffffff;
}

.activated {
  background-color: #e8fff3;
  padding-bottom: 4px;
  padding-top: 2px;
  color: #50cd89;
  border-radius: 6px;
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  text-align: center;
  width: 125px;
}

.not_subscribed {
  background-color: #ffe2e5;
  padding-bottom: 4px;
  padding-top: 2px;
  color: #f64e60;
  border-radius: 6px;
  /* padding-left: 10px; */
  /* padding-right: 8px; */
  text-align: center;
  width: 125px;
}

.initiated {
  background-color: #fff4de;
  padding-bottom: 4px;
  padding-top: 2px;
  color: #ffa800;
  border-radius: 6px;
  /* padding-left: 16px; */
  /* padding-right: 16px; */
  text-align: center;
  width: 125px;
}

.pagination {
  display: flex;
  box-sizing: border-box;
  color: #7e8299;
  background-color: #ffffff;
  box-shadow: none;
  gap: 0.6rem;
  padding: 0.25rem;
  margin: 0rem, 0rem, 1rem, 1rem;
  border-radius: 6px;
}

.previous-page-btn,
.next-page-btn {
  background: #ffffff;
  border-radius: 25%;
  cursor: pointer;
}

.page-link {
  padding: 5px 10px 5px 10px;
  height: 25px;
  width: 25px;
}

.break {
  color: #7e8299;
  letter-spacing: 0.1rem;
}

.page-item.active a {
  background-color: #ff9966;
  padding: 2px 11px 2px 11px;
  border-radius: 50%;
  color: #ffffff;
}

.page-item {
  margin-left: 5px;
  align-self: center;
  cursor: pointer;
}
