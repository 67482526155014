.save-button {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  width: 100px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 5px;
}

.save-button:hover {
  background-color: #31205D;
  color: #ffffff;
  padding-bottom: 8px;
}

.cancel-button {
  background-color: #ffffff;
  color: #9ca3af;
  font-size: 14px;
  font-weight: 600;
  width: 100px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 1px solid #9ca3af;
  margin-right: 6px;
  border-radius: 5px;
}

.cancel-button:hover {
  background-color: #9ca3af;
  color: #ffffff;
  border: 1px solid #9ca3af;
}

.error-input {
  /* visibility: hidden; */
  font-size: 12px;
  margin-top: 6px;
  color: red;
  font-weight: 400;
  margin-bottom: 12px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* Optional: You can also adjust the margin to remove any remaining space */
}

/* Input style */
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-container:focus-within label {
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  transform: translate(0, 12px) scale(0.8);
  color: #9ca3af;
}

.input-container .filled {
  transform: translate(0, 12px) scale(0.8);
}

.input-container label {
  position: absolute;
  pointer-events: none;
  transform: translate(0, 23px) scale(1);
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: #6f81a5;
  font-size: 16px;
  line-height: 1;
  left: 16px;
}

.input-container input {
  height: 64px;
  border-radius: 4px;
  border-color: #9ca3af;
  /* border: none; */
  padding: 24px 16px 4px 16px;
  font-size: 16px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container input:focus {
  box-shadow: 0 0 0 2px #9ca3af;
}

.add-chapter-field-button{
  height: '16px' !important;
  width: '40px' !important;
  background-color: '#FF9966' !important;
}
